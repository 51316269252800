import { render, staticRenderFns } from "./EditMediaHandlerModuleView.vue?vue&type=template&id=2ae04b7f&"
import script from "./EditMediaHandlerModuleView.vue?vue&type=script&lang=ts&"
export * from "./EditMediaHandlerModuleView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports